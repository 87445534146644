import ContainerRegistry from './explainers/ContainerRegistry';
import UniversalSupport from './explainers/UniversalSupport';
import Upstream from './explainers/Upstream';
import MultiFormatRepositories from './explainers/MultiFormatRepositories';
import Analytics from './explainers/Analytics';
import ScanningPackageAnalysis from './explainers/ScanningPackageAnalysis';
import GlobalScale from './explainers/GlobalScale';
import SAML from './explainers/SAML';
import Quarantine from './explainers/Quarantine';
import Broadcasts from './explainers/Broadcasts';
import PolicyManagement from './explainers/PolicyManagement';
import HighAvailability from './explainers/HighAvailability';
import Logging from './explainers/Logging';
import DistributedTeams from './explainers/DistributedTeams';
import EntitlementTokens from './explainers/EntitlementTokens';
import Simplify from './explainers/Simplify';
import GetControl from './explainers/GetControl';
import ImproveDecisionMaking from './explainers/ImproveDecisionMaking';
import Streamline from './explainers/Streamline';
import UniversalFormat from './explainers/UniversalFormat';
import MigrateLegal from './explainers/MigrateLegal';
import TrueQuality from './explainers/TrueQuality';

const explainerMap = {
  ContainerRegistry,
  UniversalSupport,
  Upstream,
  MultiFormatRepositories,
  Analytics,
  ScanningPackageAnalysis,
  GlobalScale,
  SAML,
  Quarantine,
  Broadcasts,
  PolicyManagement,
  Logging,
  DistributedTeams,
  EntitlementTokens,
  HighAvailability,
  Simplify,
  GetControl,
  ImproveDecisionMaking,
  Streamline,
  UniversalFormat,
  MigrateLegal,
  TrueQuality,
};

export default explainerMap;
