import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useInView } from 'framer-motion';

const Explainer = ({
  width = 600,
  height = 600,
  viewportAmount = 0.5,
  children,
  background,
  className,
  onInView,
  once = false,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: viewportAmount, once });

  useEffect(() => {
    if (inView) {
      ref.current.setAttribute('data-inview', true);
    } else {
      ref.current.removeAttribute('data-inview');
    }
    if (onInView) {
      onInView(inView);
    }
  }, [inView, onInView]);

  return (
    <svg
      ref={ref}
      viewBox={`0 0 ${width} ${height}`}
      style={{ background }}
      className={className}
    >
      {children}
    </svg>
  );
};

Explainer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Explainer;
